const MessageExtensionSDK = () => {
  (function (d, s, id) {
    let js = d.getElementsByTagName(s)[0];
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/messenger.Extensions.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'Messenger');
};

export default MessageExtensionSDK;
