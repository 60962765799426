import React from 'react';
import LinkExpireIcon from '../../assets/images/Link_Expire.svg';

const LinkExpire = () => {
  return (
    <div className="success-container">
      <div className="verify-box">
        <img src={LinkExpireIcon} alt="Verify Success" />
        <h1>Link Expired</h1>
        <p>The verification link you are trying to access has expired.</p>
        <p className="info">
          Please ensure that you are using the most recent link provided to you. If you require further assistance,
          please contact our support team
        </p>
      </div>
    </div>
  );
};

export default LinkExpire;
