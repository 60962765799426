import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import {
  confirmOrder,
  getCatalogue,
  getPendingOrderList,
  getTax,
  removeItemFromOrderList,
  resetOrder,
} from './orderRedux/orderAction';
import Tab from '../../components/Tab/Tab';
import Item from '../../components/item/Item';
import Modal from '../../components/Modal/Modal';
import {
  AUTH_TOKEN,
  getAuthTokenLocalStorage,
  saveAuthTokenLocalStorage,
} from '../../helpers/LocalStorageHelper';
import Loader from '../../components/Loader/Loader';
import PageNotFound from '../pageNotFound/PageNotFound';
import MessageExtensionSDK from '../../helpers/messageExtensionSDK';
import { euroConverter, grandTotalAmount } from '../../utils/utils';
import Header from '../header/Header';

const Order = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, messageChannel, customerExists } = useQueryParams();
  const [confirmModal, setConfirmModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [finalList, setFinalList] = useState([]);

  const { catalogueList, tax, orderList, grandTotal, isLoading, pageNotFound } = useSelector(
    ({ order }) => order ?? {}
  );
  const tabs = useMemo(() => {
    const tabsItem = catalogueList.map(catalogue => catalogue?.categoryName.en);
    return tabsItem;
  }, [catalogueList]);

  const onClickTab = useCallback(index => {
    setActiveTab(index);
  }, []);

  useEffect(() => {
    if (Date.now() < new Date(localStorage.getItem('tokenExpiresAt')).getTime()) {
      const localAuthToken = getAuthTokenLocalStorage(AUTH_TOKEN);
      history.replace(
        `/bot?token=${localAuthToken}&messageChannel=${messageChannel}&customerExists=${customerExists}`
      );
    }
  }, []);

  const onCheckout = useCallback(() => {
    setConfirmModal(e => !e);
  }, []);
  const onConfirmOrder = useCallback(async () => {
    if (customerExists !== 'false') {
      try {
        await dispatch(confirmOrder({ items: orderList }, customerExists)).then(res => {
          if (messageChannel !== 'facebook') {
            if (res?.data?.status === 'SUCCESS') {
              history.push(
                `/order-success?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}&orderNumber=${res?.data?.data?.order?.orderNumber}`
              );
            }
          } else {
            dispatch(getPendingOrderList());
            dispatch(resetOrder());
          }
        });
        onCheckout();
      } catch (e) {
        /**/
      }
    } else if (Date.now() < new Date(localStorage.getItem('tokenExpiresAt')).getTime()) {
      try {
        await dispatch(confirmOrder({ items: orderList }, customerExists)).then(res => {
          if (res?.data?.status === 'SUCCESS') {
            dispatch(resetOrder());
            history.push(
              `/order-success?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}&orderNumber=${res?.data?.data?.order?.orderNumber}`
            );
          }
        });
        onCheckout();
      } catch (e) {
        /**/
      }
    } else {
      const jsonOrderList = encodeURIComponent(JSON.stringify(orderList));
      history.push(
        `/user-info?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}&orderList=${jsonOrderList}`
      );
    }
  }, [orderList]);

  const onDeleteOrderItem = useCallback(
    deleteOrderItem => {
      const updatedOrderList = orderList.filter(
        orderedItem => orderedItem._id !== deleteOrderItem._id
      );
      dispatch(removeItemFromOrderList(updatedOrderList, deleteOrderItem));
    },
    [orderList]
  );

  useEffect(() => {
    const list = catalogueList?.[activeTab]?.items;
    setFinalList(list);
  }, [catalogueList, activeTab]);

  useEffect(async () => {
    saveAuthTokenLocalStorage(token);
    if (customerExists !== 'false') {
      await dispatch(getPendingOrderList());
    } else {
      await dispatch(getCatalogue());
    }
  }, [token, customerExists]);

  useEffect(() => {
    MessageExtensionSDK();
    dispatch(getTax());
  }, []);

  return (
    <>
      {pageNotFound ? (
        <PageNotFound />
      ) : (
        (() =>
          isLoading ? (
            <Loader />
          ) : (
            <>
              {(messageChannel === 'whatsApp' || messageChannel === 'webView') && <Header />}
              <div className="top-menu-search-container">
                <div className="top-menu-container">
                  <Tab tabs={tabs} tabActive={onClickTab} activeTabIndex={activeTab} />
                </div>

                {/* <Input
                  type="text"
                  value={searchValue}
                  suffix="search"
                  placeholder="Search here"
                  borderClass="search-input"
                  onChange={onChangeSearchText}
                /> */}
              </div>
              <div
                className={`${
                  messageChannel === 'whatsApp' || messageChannel === 'webView'
                    ? 'tab-content-container-wp'
                    : 'tab-content-container'
                }`}
              >
                <div
                  className={`${
                    messageChannel === 'whatsApp' || messageChannel === 'webView'
                      ? `tab-content-wp ${activeTab === 0}`
                      : `tab-content ${activeTab === 0}`
                  }`}
                >
                  <>
                    <>
                      {catalogueList?.[activeTab]?.items.length <= 0 && (
                        <div className="no-record-found">Sorry, no items available right now.</div>
                      )}
                      {catalogueList?.[activeTab]?.items?.length > 0 &&
                        (finalList?.length > 0 ? (
                          <div className="items-container">
                            {finalList?.map(item => (
                              <Item key={item?._id} item={item} />
                            ))}
                          </div>
                        ) : (
                          <div className="no-record-found">No such item found</div>
                        ))}
                    </>
                  </>
                </div>
              </div>
              <button type="button" className="button checkout-button" onClick={onCheckout}>
                Checkout {euroConverter(grandTotal)}
              </button>
              {confirmModal && (
                <Modal
                  header="Order Checkout"
                  closeIcon
                  hideModal={() => setConfirmModal(false)}
                  footer={
                    <button
                      type="submit"
                      className="button confirm-button"
                      disabled={orderList.length === 0}
                      onClick={onConfirmOrder}
                    >
                      CONFIRM ORDER
                    </button>
                  }
                >
                  {orderList.length > 0 ? (
                    <div className="confirm-order-table-container">
                      <table className="confirm-order-table">
                        <thead>
                          <th align="left">
                            <b>Item Name</b>
                          </th>
                          <th align="right">
                            <b>Qty.</b>
                          </th>
                          <th align="right">
                            <b>Price</b>
                          </th>
                          <th />
                        </thead>
                        <tbody>
                          {orderList.map(order => (
                            <tr key={order?._id}>
                              <td align="left">{order.name}</td>
                              <td align="right">{order.quantity}</td>
                              <td align="right">
                                {euroConverter(order.price * order.quantity ?? 0)}
                              </td>
                              <td
                                width={40}
                                align="center"
                                valign="middle"
                                onClick={() => onDeleteOrderItem(order)}
                              >
                                <span className="material-icons delete-item-icon">
                                  delete_outline
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no-record-found">
                      You have not selected any item to place order. Please select items.
                    </div>
                  )}
                  {orderList.length > 0 && (
                    <>
                      {/* <div className="sub-total">
                        Sub Total: {euroConverter(subTotalAmount(grandTotal, tax))}
                      </div>
                      <div className="vat-details">
                        Vat({tax.vat}%): {euroConverter(calculateVat(grandTotal, tax))}
                      </div> */}
                      <div className="grand-total">
                        Total: {euroConverter(grandTotalAmount(grandTotal, tax))}
                      </div>
                    </>
                  )}
                </Modal>
              )}
            </>
          ))()
      )}
    </>
  );
};

export default Order;
