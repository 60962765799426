import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AUTH_TOKEN, getAuthTokenLocalStorage } from '../../helpers/LocalStorageHelper';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import { getPendingOrderList, resetOrder } from '../order/orderRedux/orderAction';
import './_success.scss';

const OrderSuccess = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token, messageChannel, customerExists, orderNumber } = useQueryParams();

  const goToMainMenu = () => {
    dispatch(resetOrder());
    if (customerExists !== 'false') {
      dispatch(getPendingOrderList());
      history.push(
        `/bot?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}`
      );
    } else {
      const localAuthToken = getAuthTokenLocalStorage(AUTH_TOKEN);
      history.push(
        `/bot?token=${localAuthToken}&messageChannel=${messageChannel}&customerExists=${customerExists}`
      );
    }
  };

  return (
    <>
      <div className="success-container">
        <div className="center-container">
          <div className="main-container">
            <div className="check-container">
              <div className="check-background">
                <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 25L27.3077 44L58.5 7"
                    stroke="#40a1ef"
                    strokeWidth="13"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="check-shadow" />
            </div>
          </div>
          <div className="success-text mt-3">Your order has been placed successfully.</div>
          {orderNumber && (
            <div className="order-number">
              Order number: <span>#{orderNumber}</span>
            </div>
          )}
          <div className="mt-20">
            <button onClick={goToMainMenu} type="button" className="main-menu-btn mt-20">
              Go to Main Menu
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
