import React, { useState, useEffect } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import btIcon from '../../assets/images/bt_icon.svg';
import Input from '../../components/Input/Input';
import './_user-info.scss';
import 'react-phone-input-2/lib/style.css';
import { useQueryParams } from '../../hooks/GetQueryParamHook';
import { confirmOrder, getTable } from '../order/orderRedux/orderAction';
import { errorNotification } from '../../components/Toast';
import { saveAuthTokenLocalStorage } from '../../helpers/LocalStorageHelper';

const UserInformation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerName, setCustomerName] = useState('');
  const [tableNumber, setTableNUmber] = useState('');
  const [isSittingOnTable, setSittingOnTable] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');
  const { orderList, token, messageChannel, customerExists } = useQueryParams();
  const { tableNUmberList } = useSelector(({ order }) => order ?? {});
  const [isLoading, setIsLoading] = useState(false);

  const onHandleSubmit = () => {
    const orderListData = JSON.parse(orderList);
    if (customerName?.toString()?.trim()?.length === 0) {
      errorNotification('Please enter customer name!');
    } else if (isSittingOnTable?.toString()?.trim()?.length === 0) {
      errorNotification('Please select table option!');
    } else {
      setIsLoading(true);
      let apiData = {};
      if (isSittingOnTable === 'true') {
        if (tableNumber?.toString()?.trim()?.length === 0) {
          errorNotification('Please select table number!');
          setIsLoading(false);
        } else {
          apiData = {
            items: orderListData,
            customerName,
            customerNumber,
            tableNumber,
            isSittingOnTable: true,
          };

          dispatch(confirmOrder(apiData, customerExists)).then(res => {
            setIsLoading(false);
            if (res?.data?.status === 'SUCCESS') {
              saveAuthTokenLocalStorage(res?.data?.data.token);
              localStorage.setItem('tokenExpiresAt', res?.data?.data?.tokenExpiresAt);
              history.push(
                `/order-success?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}&orderNumber=${res?.data?.data?.order?.orderNumber}`,
              );
            }
          });
        }
      } else if (isSittingOnTable === 'false') {
        if (customerNumber?.toString()?.trim()?.length === 0) {
          errorNotification('Please enter mobile number!');
          setIsLoading(false);
        } else {
          apiData = {
            items: orderListData,
            customerName,
            customerNumber,
            isSittingOnTable: false,
          };

          dispatch(confirmOrder(apiData, customerExists)).then(res => {
            setIsLoading(false);
            if (res?.data?.status === 'SUCCESS') {
              saveAuthTokenLocalStorage(res?.data?.data.token);
              localStorage.setItem('tokenExpiresAt', res?.data?.data?.tokenExpiresAt);
              history.push(
                `/order-success?token=${token}&messageChannel=${messageChannel}&customerExists=${customerExists}&orderNumber=${res?.data?.data?.order?.orderNumber}`,
              );
            }
          });
        }
      }
    }
  };

  const onChangeNumber = (value, country) => {
    setCustomerNumber(country.dialCode !== value ? value.replace(country.dialCode, `(${country.dialCode})`) : '');
  };

  useEffect(() => {
    dispatch(getTable());
  }, []);

  return (
    <>
      <div className="container">
        <div className="center-container">
          <img src={btIcon} alt="" />
          <div className="text-content">
            <div className="title">Café au Lait</div>
            <div className="description">Please fill your details to place the order</div>
          </div>
          <div className="form-input">
            <Input
              type="text"
              borderClass="form-control"
              value={customerName}
              onChange={e => setCustomerName(e.target.value)}
              placeholder="Name"
              name="name"
            />
          </div>
          <div className="radio-group mt-3">
            <label className="radio-text">Are you sitting at the table?</label>
            <div className="radio">
              <input
                id="radio-1"
                value="true"
                onChange={e => setSittingOnTable(e.target.value)}
                name="radio"
                type="radio"
              />
              <label htmlFor="radio-1" className="radio-label">
                Yes
              </label>
            </div>
            <div className="radio">
              <input
                id="radio-2"
                value="false"
                onChange={e => setSittingOnTable(e.target.value)}
                name="radio"
                type="radio"
              />
              <label htmlFor="radio-2" className="radio-label">
                No
              </label>
            </div>
          </div>
          <div className="mt-3">
            {isSittingOnTable === 'true' && (
              <>
                <select
                  name="tableNumber"
                  style={{ outline: 'none', background: '#FFF' }}
                  value={tableNumber}
                  onChange={e => setTableNUmber(e.target.value)}
                  className="form-control"
                >
                  <option defaultValue="" selected>
                    Select Table
                  </option>
                  {tableNUmberList?.tableNumbers?.map(tableNo => (
                    <option key={Math.random()} value={tableNo}>
                      {tableNo}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
          <div className="mt-3">
            <ReactPhoneInput
              placeholder="Mobile Number"
              onChange={(value, country) => onChangeNumber(value, country)}
              value={customerNumber}
              countryCodeEditable={false}
              name="mobileNumber"
              country="be"
              inputStyle={{
                width: '100%',
                height: '49px',
                fontSize: '13px',
                paddingLeft: '48px',
                borderRadius: '5px',
              }}
            />
          </div>
          <div className="form-group mt-3">
            <button onClick={onHandleSubmit} type="button" className="form-btn" disabled={isLoading ? 'disabled' : ''}>
              {isLoading ? <span className="loader" /> : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInformation;
