import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOnClickOutside } from '../../hooks/UserClickOutsideHook';

const Modal = props => {
  const {
    header,
    buttons,
    children,
    headerClassName,
    bodyClassName,
    className,
    hideModal,
    closeIcon,
    footer,
    closeClassName,
    ...restProps
  } = props;
  const dialogContentClass = `modal-content ${className}`;
  const dialogHeaderClass = `modal-header ${headerClassName}`;
  const dialogBodyClass = `modal-body ${bodyClassName}`;
  const closeClass = `material-icons-round cursor-pointer ${closeClassName}`;

  const modalRef = useRef();
  useOnClickOutside(modalRef, () => hideModal(false));

  return (
    <div className="modal">
      <div className={dialogContentClass} ref={modalRef} {...restProps}>
        <div className={dialogHeaderClass}>
          {header}
          {closeIcon && (
            <span title="Close modal" className={closeClass} onClick={() => hideModal(false)}>
              close
            </span>
          )}
        </div>
        <div className={dialogBodyClass}>{children}</div>
        <div className="modal-footer">{footer}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  header: PropTypes.string,
  buttons: PropTypes.array,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  hideModal: PropTypes.func,
  closeIcon: PropTypes.bool,
  closeClassName: PropTypes.string,
  footer: PropTypes.element,
  children: PropTypes.node,
};

Modal.defaultProps = {
  header: '',
  buttons: [],
  className: '',
  headerClassName: '',
  bodyClassName: '',
  closeIcon: true,
  closeClassName: '',
  children: null,
  footer: '',
  hideModal: () => {},
};

export default Modal;
