import React from 'react';
import VerifySuccessIcon from '../../assets/images/Verify_Success.svg';

const VerifySuccess = () => {
  return (
    <div className="success-container">
      <div className="verify-box">
        <img src={VerifySuccessIcon} alt="Verify Success" />
        <h1>Verification Successful</h1>
        <p>
          Congratulations! Your verification is successful. You&apos;re all set to enjoy our services. Thank you for
          choosing us!
        </p>
      </div>
    </div>
  );
};

export default VerifySuccess;
