import React from 'react';

const Loader = () => {
  return (
    <div className="loader-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        width="20vw"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        className="wine-glass"
      >
        <path d="M6 3v6c0 2.97 2.16 5.43 5 5.91V19H8v2h8v-2h-3v-4.09c2.84-.48 5-2.94 5-5.91V3H6zm10 5H8V5h8v3z" />
      </svg>
      <div className="progress-bar-striped">
        <div />
      </div>
    </div>
  );
};

export default Loader;
