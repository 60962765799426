import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import defaultImage from '../../assets/images/wine_bar_black_24dp.svg';

const Image = props => {
  const { src, className, ...restProps } = props;
  
  const onError = useCallback(
    e => {
      e.target.src = defaultImage;
    },
    [defaultImage]
  );

  return <img src={src ?? defaultImage} className={className} onError={onError} {...restProps} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: '',
};

export default Image;
