// import {AUTH_TOKEN} from '../helpers/LocalStorageHelper';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// const token = getAuthTokenLocalStorage();

export const ORDER_URLS = {
  getCatalogueList: `${BASE_URL}web-view/menu`,
  getPendingOrderList: `${BASE_URL}web-view/draft-order`,
  getTax: `${BASE_URL}web-view/organization-settings`,
  confirmOrder: `${BASE_URL}web-view/confirm-order`,
  getTableNumber: `${BASE_URL}web-view/tables`,
};

export const VERIFY_URL = `${BASE_URL}web-view/redirect`;
