import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ApiService from '../../constants/ApiService';
import { VERIFY_URL } from '../../constants/UrlConstants';

const Verifying = () => {
  const { token } = useParams();
  const history = useHistory();

  useEffect(async () => {
    if (token) {
      try {
        const response = await ApiService.putData(`${VERIFY_URL}/${token}`);
        if (response?.data?.status === 'SUCCESS') {
          history.push('/verify-success');
        }
      } catch (error) {
        history.push('/link-expire');
      }
    }
  });

  return (
    <div className="success-container verifying-box">
      <div>
        <span className="loader loader-lg" />
      </div>
      <h1>Verifying</h1>
      <p>
        Verification is underway, safeguarding your security. We&lsquo;ll be done shortly, and you&lsquo;ll be on your
        way!
      </p>
    </div>
  );
};

export default Verifying;
