export const AUTH_TOKEN = 'token';

export const getAuthTokenLocalStorage = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const saveAuthTokenLocalStorage = authToken => {
  if (Date.now() < new Date(localStorage.getItem('tokenExpiresAt')).getTime()) {
    const localAuthToken = getAuthTokenLocalStorage(AUTH_TOKEN);
    localStorage.setItem(AUTH_TOKEN, localAuthToken);
  } else {
    localStorage.setItem(AUTH_TOKEN, authToken);
  }
};

export const clearAuthToken = () => {
  sessionStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_TOKEN);
};
